import icHome from '@iconify/icons-ic/home';
import icPhone from '@iconify/icons-ic/perm-phone-msg';
import {NavigationItem} from '../@vex/interfaces/navigation-item.interface';

// tslint:disable-next-line:variable-name
export const MenuCallCenter: NavigationItem[] = [
    {
        type: 'link',
        label: 'Inicio',
        route: '/admin/panel',
        icon: icHome
    },
    {
        type: 'link',
        label: 'Call center',
        route: '/admin/call-center',
        icon: icPhone
    }
];
