import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {ApiService} from '../services/api.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers/app.reducers';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  user: any;
  constructor(
    private router: Router,
    private readonly apiService: ApiService,
    private store: Store<AppState>
  ) {
    this.store.select('userInfo').subscribe(value => (this.user = value));
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permission = this.user?.roles?.find(role => (role?.rol_identificador === 'ROLE_ADMIN'));
    if (permission) {
      return true;
    }
    this.apiService.logout();
    return false;
  }
}
