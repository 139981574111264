import {ActionReducerMap} from '@ngrx/store';
import * as userInformation from '../reducers/user.reducer';

export interface AppState {
  userInfo: any;
}

export const appReducer: ActionReducerMap<AppState> = {
  userInfo  : userInformation.userInfoReducer,
};
