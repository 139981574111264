import icHome from '@iconify/icons-ic/home';
import icBackup from '@iconify/icons-ic/backup';
import icPhone from '@iconify/icons-ic/perm-phone-msg';
import icWork from '@iconify/icons-ic/work';
import icReport from '@iconify/icons-ic/outline-report';
import icCorporate from '@iconify/icons-ic/outline-corporate-fare';
import icSupervisorAccount from '@iconify/icons-ic/supervisor-account';
import {NavigationItem} from '../@vex/interfaces/navigation-item.interface';

// tslint:disable-next-line:variable-name
export const Menu: NavigationItem[] = [
    {
        type: 'link',
        label: 'Inicio',
        route: '/admin/panel',
        icon: icHome
    },
    {
        type: 'link',
        label: 'Importar',
        route: '/admin/import',
        icon: icBackup
    },
    {
        type: 'link',
        label: 'Call center',
        route: '/admin/call-center',
        icon: icPhone
    },
    {
        type: 'link',
        label: 'Asignar socios',
        route: '/admin/partners',
        icon: icWork
    },
    {
        type: 'link',
        label: 'Reporte',
        route: '/admin/report',
        icon: icReport
    },
    {
        type: 'subheading',
        label: 'Catálogos',
        children: [
            {
                type: 'link',
                label: 'Empresas',
                route: '/admin/catalogs/business',
                icon: icCorporate
            },
            {
                type: 'link',
                label: 'Usuarios',
                route: '/admin/catalogs/users',
                icon: icSupervisorAccount
            },
            /*{
                type: 'dropdown',
                label: 'Help Center',
                icon: icLayers,
                children: [
                    {
                        type: 'link',
                        label: 'Getting Started',
                        route: '/apps/help-center/getting-started'
                    },
                    {
                        type: 'link',
                        label: 'Pricing & Plans',
                        route: '/apps/help-center/pricing'
                    }
                ]
            }*/
        ]
    },
];
