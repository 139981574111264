import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import {AuthGuard} from './core/guards/auth.guard';
import {AdminGuard} from './core/guards/admin.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'reset/:token',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'admin',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'import',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/import-excel/import-excel.module').then(m => m.ImportExcelModule),
      },
      {
        path: 'import',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/import-excel/detail/import-detail.module').then(m => m.ImportDetailModule),
      },
      {
        path: 'partners',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule),
      },
      {
        path: 'report',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/report/report.module').then(m => m.ReportModule),
      },
      {
        path: 'catalogs',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/catalogs/business/business.module').then(m => m.BusinessModule),
      },
      {
        path: 'catalogs',
        canActivate: [AdminGuard],
        loadChildren: () => import('./pages/catalogs/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'edit-profile',
        loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
      },
      // Llamadas para el call center
      {
        path: 'call-center',
        loadChildren: () => import('./pages/call-center/call-center.module').then(m => m.CallCenterModule),
      },
      {
        path: 'call-center',
        loadChildren: () => import('./pages/call-center/parnert-detail/parnert-detail.module').then(m => m.ParnertDetailModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
