import {Action} from '@ngrx/store';

export const USER_INFO = '[USER_INFO] Mantiene la información del usuario logeado';

export class UserInfoAction implements Action {
  readonly type = USER_INFO;

  constructor( public user: any) {}
}

export type Actions = UserInfoAction;
