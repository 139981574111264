import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers/app.reducers';
import {UserInfoAction} from '../../reducers/user.action';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })

export class ApiService {

  private url = environment.api;

  constructor(
      private http: HttpClient,
      private readonly router: Router,
      private readonly store: Store<AppState>,
      private snackbar: MatSnackBar,
  ) { }

  apiRequest(action: string, data: any = {}, verb: string = 'post'): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        this.http[verb](this.url + action, data).subscribe(
            response => resolve(response),
            error => reject(error)
        );
      } catch (e) {
        reject(e);
      }
    });
  }
  apiRequestAsync(action: string, data: any = {}, verb: string = 'post'): Observable<any> {
    return this.http[verb](this.url + action, data);
  }

  logout() {
    this.store.dispatch(new UserInfoAction(null));
    localStorage.clear();
    sessionStorage.clear();
    this.snackbar.open('Te esperamos pronto.', 'LOGOUT', {duration: 1000});
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  }

  downloadFile(action: string, content: string): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Content-type', content);

    return new Promise<any>(resolve => {
      this.http.get(this.url + action, { headers, responseType: 'blob' }).subscribe(
        response => resolve(response),
        error => resolve(error)
      );
    });
  }
  getApiRequestStorage(action: string, content: string, data: any = null): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Content-type', content);

    return new Promise<any>(resolve => {
      this.http.get(environment.urlStorage + action, { headers, responseType: 'blob' }).subscribe(
        response => resolve(response),
        error => resolve(error)
      );
    });
  }

  async apiMultipart(action, formFieldData: FormData) {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
    headers.append('Accept', 'application/json');

    return await fetch(this.url + action, {
      method: 'POST',
      body: formFieldData,
      headers
    }).then(response => response.json());
  }

  fileToBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}

