<button [matBadge]="numNot"
        matBadgeColor="warn"
        #originRef
        (click)="showPopover()"
        [class.active]="dropdownOpen"
        class="button"
        mat-icon-button
        type="button">
  <mat-icon class="force" [icIcon]="icNotificationsActive"></mat-icon>
</button>
