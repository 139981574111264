import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import {ApiService} from '../../../../app/core/services/api.service';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;
  numNot: number;

  constructor(private popover: PopoverService,
              private readonly apiService: ApiService,
              private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.getNot();
  }

  getNot() {
    console.log('actualizar');
    this.apiService.apiRequest('get_notificaciones', {}, 'get').then(
        response => {
          if (response.status === 'success' && response?.data?.length) {
            this.numNot = response?.data?.length;
          } else {
            this.numNot = null;
          }
        }
    );
  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
    this.getNot();
    popoverRef.afterClosed$.subscribe(() => {
      this.getNot();
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
