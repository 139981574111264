import * as userAction from './user.action';

const initialState: any = JSON.parse(localStorage.getItem('ue'));

export function userInfoReducer(state = initialState, action: userAction.Actions): string {
  switch (action.type) {
    case userAction.USER_INFO:
      localStorage.setItem('ue', JSON.stringify(action.user));
      return action.user;
    default:
      return state;
  }
}
