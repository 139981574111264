<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">Notificaciones</div>
      <div class="dropdown-subheading">Ud tiene {{ notifications.length }} notificacione<span *ngIf="notifications.length !== 1">s</span>.</div>
    </div>
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications; trackBy: trackById"
       [class.read]="notification.read"
       [routerLink]="[]"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       (click)="checkedItem(notification)"
       matRipple>
      <ic-icon [icon]="notification.icon"
               [ngClass]="notification.colorClass"
               class="notification-icon"
               fxFlex="none"
               size="24px"></ic-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ notification.label }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <button color="primary" mat-button type="button" (click)="checkAll()">Marcar todas como vistas</button>
  </div>
</div>
