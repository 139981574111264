import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { trackById } from '../../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import {ApiService} from '../../../../../app/core/services/api.service';
import {Router} from '@angular/router';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  notifications: Notification[] = [];

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  trackById = trackById;

  constructor(
      private readonly apiService: ApiService,
      private readonly router: Router
  ) { }

  ngOnInit() {
    this.apiService.apiRequest('get_notificaciones', {}, 'get').then(
        response => {
          if (response.status === 'success' && response?.data?.length) {
            this.notifications = response?.data?.map(value => {
              return {
                id: value?.not_id,
                label: value?.not_texto,
                icon: icCheckCircle,
                colorClass: 'text-green',
                datetime: value?.updated_at,
              };
            });
          }
        }
    );
  }

  checkAll() {
    this.notifications.filter(value => {
      this.checkedItem(value);
    });
  }
  checkedItem(item: Notification) {
    this.apiService.apiRequest('update_notificacion', {not_id: item.id}, 'put').then(
        response => {
          if (response.status === 'success') {
            this.notifications = this.notifications?.filter(value => (value.id !== item.id));
          }
        }
    );
    this.router.navigate(['/admin/partners']);
  }
}
