export interface ColorVariable {
  light: string;
  default: string;
  contrast: string;
}

export const colorVariables: Record<string, ColorVariable> = {
  'azul-oscuro': {
    light: 'rgba(92, 119, 255, .1)',
    default: 'rgb(28,120,161)',
    contrast: 'rgb(255, 255, 255)'
  },
  'azul-medium': {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(44,149,196)',
    contrast: 'rgb(255, 255, 255)'
  },
  'azul-claro': {
    light: 'rgba(158, 158, 158, 0.1)',
    default: 'rgb(3,169,245)',
    contrast: 'rgb(255, 255, 255)'
  },
};
